import { StaticImage } from "gatsby-plugin-image";
import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";

const PinkCoLanding = () => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  return (
    <main>
      <section className="py-24">
        <div className="xl:container mx-auto px-6 grid lg:grid-cols-2 items-center gap-x-12 gap-y-12">
          <div className="flex h-full">
            <StaticImage src="../../images/Sourci_ 455-975w.jpg" 
              alt=""
              placeholder="blurred"
              className="rounded-3xl" />
          </div>
          <div>
            <p className="font-black text-3xl lg:text-4xl">
            Started just over 4 years ago in 2017 by founders Giovanni Pino and Ellie Vaisman, Sourci imported over $100m of consumer goods in the last 2 years alone. Here's how we work with Pink Cosmetics Co. to manufacture their beauty tool range and continue to take the brand from strength to strength.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Pink Cosmetics Co.<br />
              Redefining A Classic<br />
              Beauty Tool.<br />
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Pink Cosmetics was founded by best friends Aleyna and Anisa. With an expert understanding of all things beauty, they set out to redefine a classic beauty tool - the eyelash curler. They have successfully revolutionized a staple tool in everyone's makeup kit. Their Lash Tool has an innovative comb attachment, curved apex, ergonomic handles, permanent lash pads and is made of high-grade steel.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Pink Cosmetics Co.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/9ed88398-e39f-442a-8b59-db28b72c3a26_Shopifyproductlistings_15_720x.jpg"
                alt=""
                className="object-cover min-w-[100%]"
                placeholder="blurred" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-3 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>300+</span>
            </p>
            <p className="font-black text-xl">HOURS OF WORK</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>15</span>
            </p>
            <p className="font-black text-xl">MANUFACTURERS TENDERED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>$120,000</span>
            </p>
            <p className="font-black text-xl">ORDER VALUE</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/ace8f56a-c77a-4bc0-a353-3f22baf4aca5_Shopifyproductlistings_21_2048x-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            Set The Bar High.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Aleyna and Anisa had found a manufacturer online that was proving to be less than ideal. They were experiencing significant quality issues with their Lash Tool. This meant that their defect rate was high and their customer returns were climbing. While simple in design, the Lash Tool is complex in construction and assembly and their manufacturer was unable to meet the necessary quality standard for each component of the product.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The Sourci team got to work to overhaul the existing Pink Cosmetics Co. supply chain. Our China team were able to provide a variety of manufacturing options to Aleyna and Anisa with the objective of lowering defect rates by working with a high-quality supplier that had the capacity to grow and scale with the brand in the future without compromising on quality.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Following this, we facilitated the creation of prototypes to address areas of concern with the existing product to ensure that we could improve the overall quality of the product. We also ensured that quality assurance was integrated into the supply chain management process to ensure that risk and error was mitigated in product assembly.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The Sourci team also ensured that all production runs had detailed quality control checks at various stages to validate the quality of the large-scale production of the Lash Tool.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            Great Marketing + A Cult Fave.
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Having a strong team in Sourci that were able to manage the end-to-end supply chain for Pink Cosmetics Co., Aleyna and Anisa were able to focus on what they do best, ensuring their marketing and branding was strong.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With this in mind, it's no surprise that the Lash Tool has become a cult favourite with their product going viral on TikTok.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The duo are now working with the team at Sourci to develop two new amazing complimentary products, with plans to launch them both in 2022.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Pink Cosmetics Co.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/288610cb-3bc4-4851-a0f7-803b3c7e0d8a_Shopifyproductlistings_19_720x-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]"
                placeholder="blurred" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-4xl lg:text-5xl mb-12">
            See if we can help with your product
          </h3>
          <a href="https://sourci.leadshook.io/survey/Snar5vhx9dVnFUtuv0dYztx5DEfYI2Pma7csY19s"
            rel="noreferrer noopener"
            target="_blank"
            className="text-white relative rounded-xl justify-center px-12 py-4 whitespace-nowrap bg-sourci-teal hover:bg-sourci-teal-100 text-xl transition-colors">
            Get Started {'>'}
          </a>
        </div>
      </section>

    </main>
  )
}

export default PinkCoLanding;

export function Head() {
  return (
    <Seo title="Pink Cosmetics Co. | Sourci"
      description="Pink Cosmetics was founded by best friends Aleyna and Anisa. With an expert understanding of all things beauty, they set out to redefine a classic beauty tool - the eyelash curler."
      canonical="/lp/pinkcosmeticsco" />
  )
}